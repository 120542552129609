import Network from "@/services/network";
import {ref} from 'vue';

export default function handleDepartments() {
    const network = new Network;
    const loading = ref(false);
    const uri = '/procurement/organization-setting/department';

    const fetchDepartmentList = (query) => {
        return network.api('get', uri + query);
    }

    const createNewDepartment = (data) => {
        return network.api('post', uri, data);
    }

    const updateDepartment = (id, data) => {
        return network.api('put', `${uri}/${id}`, data);
    }

    return {
        loading,
        fetchDepartmentList,
        createNewDepartment,
        updateDepartment,
    }
}