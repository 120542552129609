<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
        :showBtn="false"
        :showAddNew="false"
        title="Requisition Approval Register"
        @onClickAddNewButton="onClickAddNewButton"
      />

      <DateQuerySetter @onClickGo="getRequisitions">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Select Project</label>
          <v-select
            placeholder="Select Project"
            :options="projects"
            label="name"
            :reduce="name => name.id"
            v-model="projectId"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Department</label>
          <v-select
            placeholder="Select Department"
            v-model="departmentId"
            :options="departments"
            label="name"
            :reduce="name => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Requisition By</label>
          <v-select
            placeholder="Select Requisition By"
            v-model="requisitionUserId"
            :options="requisitionUsers"
            label="name"
            :reduce="name => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Status</label>
          <v-select
            placeholder="Select Status"
            v-model="status"
            :options="approvalStatus"
            label="name"
            :reduce="name => name.id"
          />
        </div>
      </DateQuerySetter>
    </div>

    <ListingTableReview
      :approvalPageId="pageId"
      :status="status"
      :requisition-list="requisitionList"
      :approvalStatusList="approvalStatus"
      @statusUpdated="getRequisitions()"
    />
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
    <global-loader v-if="isLoading" />
  </div>
</template>

<script setup>
import {computed, ref, onMounted} from 'vue';
import TitleButton                from '@/components/atom/TitleButton'
import DateQuerySetter            from '@/components/atom/DateQuerySetter'
import Pagination                 from '@/components/atom/Pagination'
import {useRoute, useRouter}      from "vue-router";
import handleRequisitions         from "@/services/modules/procurement/requisition";
import handleProjects             from "@/services/modules/procurement/project";
import handleDepartments          from "@/services/modules/procurement/organization-settings/department";
import handleInventory            from "@/services/modules/inventory";
import ListingTableReview from "@/components/molecule/procurement/requisition/register/ListingTableReview.vue";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {useStore} from "vuex";

const {fetchRequisitionList, getRequisitionUserList} = handleRequisitions();
const {fetchProjects} = handleProjects();
const {fetchDepartmentList} = handleDepartments();
const {fetchHome} = handleInventory();
const router = useRouter()
const route = useRoute()
const store = useStore()
const offset = ref(10);
const pageId = route.params.pageId;

let page = ref(1);
let loading = ref(false);
let requisitionList = ref([]);
let projectId = ref(null);
const isLoading = ref(false);
const projects = ref([]);
const departmentId = ref(null);
const departments = ref([]);
const requisitionUserId = ref(null);
const status = ref(null);
const requisitionUsers = ref([]);
const homeContent = ref([]);
let companyId = computed(() => route.params.companyId);

const approvalStatus = computed(() => {
  let approvalStatus = [];
  if(homeContent.value.hasOwnProperty('approval_status')) {
    Object.keys(homeContent.value.approval_status).forEach( id => {
      approvalStatus.push({
        id: id,
        name: homeContent.value.approval_status[id],
      })
    })
  }
  return approvalStatus;
})

function onClickAddNewButton() {
  router.push({name: 'requisition-add', params: route.params, query: route.query})
}

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&start_date=' + route.query.start
  query += '&end_date=' + route.query.end
  query += '&review=' + 1
  query += '&without_user_check=' + 1
  query += '&page_id=' + pageId
  if(projectId.value !== null) {
    query += '&project_id=' + projectId.value
  }
  if(departmentId.value !== null) {
    query += '&department_id=' + departmentId.value
  }
  if(requisitionUserId.value !== null) {
    query += '&user_id=' + requisitionUserId.value
  }
  if(status.value !== null) {
    query += '&status=' + status.value
  }
  query += '&page=' + route.query.page ?? page.value
  return query
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    getRequisitions();
  }, 100)
}

async function getRequisitions() {
  try {
    requisitionList.value = [];
    const res = await fetchRequisitionList(getQuery());
    if (res.status) {
      requisitionList.value = res.data;
      setPaginationData({
        records: res.data.total,
        perPage: res.data.per_page,
        currentPage: res.data.current_page,
      })
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};

onMounted(async () => {
  isLoading.value = true;
  let query = '?company_id=' + companyId.value
  let workflowQuery = query + `&approval_page_id=${route.params.pageId}`;

  Promise.all([
    getRequisitions(),
    fetchProjects(query).then( res => {
      if(res.status) {
        projects.value = res.data;
      }
    }),
    fetchHome().then( res => {
      if(res.status) {
        homeContent.value = res.data;
      }
    }),
    fetchDepartmentList(query).then( res => {
      if(res.status) {
        departments.value = res.data;
      }
    }),
    getRequisitionUserList(query).then( res => {
      if(res.status) {
        requisitionUsers.value = res.data;
      }
    }),
  ])
    .then ( () => isLoading.value = false)
    .catch( (err) => {
      isLoading.value = false
      showError( err.response.message )
    })
    .finally( () => isLoading.value = false)
})
</script>
